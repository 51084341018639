import "../../assets/styles/InfoUser.css";
import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

function DataConfirm({ user, dataCheck, dataUpdate, webSetting }) {
  const location = useLocation();
  const [copySuccess, setCopySuccess] = useState("");
  const mailTo = webSetting?.email;
  let phoneNumber = webSetting?.phone_number;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
 
  useEffect(() => {
    setCopySuccess("Đã sao chép nội dung.");
    setTimeout(() => {
      setCopySuccess("");
    }, 5000);
  }, []);
  const updateName = {
    title: `THAY ĐỔI TÊN TÀI KHOẢN`,
    content: `Tên đăng nhập: ${user.account}\r\nHọ tên cũ: ${dataCheck.name}\r\nHọ tên mới: ${dataUpdate.name}\r\nNgân hàng: ${dataCheck.bank_number}-${dataCheck.bank_name}`,
  };
  const updatePhoneNumber = {
    title: `THAY ĐỔI SỐ ĐIỆN THOẠI`,
    content: `Tên đăng nhập: ${user.account}\r\nSố điện thoại mới: ${dataUpdate.phone_number}`,
  };
  const updateBank = {
    title: `THAY ĐỔI TÀI KHOẢN NGÂN HÀNG`,
    content: `Tên đăng nhập: ${user.account}\r\nNgân hàng mới: ${dataUpdate.bank_number}-${dataUpdate.bank_name}`,
  };
  const updateEmail = {
    title: `THAY ĐỔI EMAIL`,
    content: `Tên đăng nhập: ${user.account}\r\nEmail mới: ${dataUpdate.email}`,
  };
  const copyToClipboardAndSendMailSMS = async (textUpdate) => {
    try {
      await navigator.clipboard.writeText(textUpdate.content);
      setCopySuccess("Đã sao chép nội dung.");
      setTimeout(() => {
        setCopySuccess("");
      }, 30000);
      if (user.send_type === "phone_number") {
        const smsLink = `sms:${phoneNumber}?body=${encodeURIComponent(textUpdate.content)}`;
        window.location.href = smsLink;
      }
    } catch (err) {
      setCopySuccess("Sao chép không thành công.");
      console.error("Không thể sao chép văn bản: ", err);
    }
  };

  const SendButton = ({send_type, isMobile, mailTo, phoneNumber, textUpdate }) => {
    return (
      send_type === "email" ? (
      <div className="btn-send type-send">
        <a
          href={isMobile 
            ? `mailto:${mailTo}?subject=${encodeURIComponent(textUpdate.title)}&body=${encodeURIComponent(textUpdate.content)}`
            : `https://mail.google.com/mail/?view=cm&fs=1&to=${mailTo}&su=${encodeURIComponent(textUpdate.title)}&body=${encodeURIComponent(textUpdate.content)}`
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          Gửi Email
        </a>
      </div>
      ) : isMobile && (
      <div className="btn-send type-send">
        <a
          href={`sms:${phoneNumber}?body=${encodeURIComponent(textUpdate.content)}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Gửi Tin Nhắn
        </a>
      </div>
      )
    );
  };

  switch (user.update_type) {
    case "full_name":
      var textUpdate = updateName;
      return (
        <div className="content-text-confirm">
          <div className="item-info">
            <p className="label-info">Tên đăng nhập:</p>
            <p className="info-text">{user.account}</p>
          </div>
          <div className="item-info">
            <p className="label-info">Họ tên cũ:</p>
            <p className="info-text">{dataCheck.name}</p>
          </div>
          <div className="item-info">
            <p className="label-info">Họ tên mới:</p>
            <p className="info-text">{dataUpdate.name}</p>
          </div>
          <div className="item-info">
            <p className="label-info">Ngân hàng:</p>
            <p className="info-text">
              {dataCheck.bank_number}-{dataCheck.bank_name}
            </p>
          </div>
          <div className="box-footer">
            <div className="btn-copy-text">
              <button onClick={() => copyToClipboardAndSendMailSMS(textUpdate)}> Sao chép nội dung </button>
              {copySuccess && <p className="ss-copy">{copySuccess}</p>}
            </div>
            < SendButton
              send_type = {user.send_type}
              isMobile = {isMobile}
              mailTo = {mailTo}
              phoneNumber = {phoneNumber}
              textUpdate = {textUpdate}
            />
          </div>
        </div>
      );
    case "phone_number":
      var textUpdate = updatePhoneNumber;
      return (
        <div className="content-text-confirm">
          <div className="item-info">
            <p className="label-info">Tên đăng nhập:</p>
            <p className="info-text">{user.account}</p>
          </div>
          <div className="item-info">
            <p className="label-info">Số điện thoại mới:</p>
            <p className="info-text">{dataUpdate.phone_number}</p>
          </div>
          <div className="box-footer">
            <div className="btn-copy-text">
              <button onClick={() => copyToClipboardAndSendMailSMS(textUpdate)}> Sao chép nội dung </button>
              {copySuccess && <p className="ss-copy">{copySuccess}</p>}
            </div>
            < SendButton
              send_type = {user.send_type}
              isMobile = {isMobile}
              mailTo = {mailTo}
              phoneNumber = {phoneNumber}
              textUpdate = {textUpdate}
            />
          </div>
        </div>
      );
    case "email":
      var textUpdate = updateEmail;
      return (
        <div className="content-text-confirm">
          <div className="item-info">
            <p className="label-info">Tên đăng nhập:</p>
            <p className="info-text">{user.account}</p>
          </div>
          <div className="item-info">
            <p className="label-info">Email mới:</p>
            <p className="info-text">{dataUpdate.email}</p>
          </div>
          <div className="box-footer">
            <div className="btn-copy-text">
              <button onClick={() => copyToClipboardAndSendMailSMS(textUpdate)}> Sao chép nội dung </button>
              {copySuccess && <p className="ss-copy">{copySuccess}</p>}
            </div>
            < SendButton
              send_type = {user.send_type}
              isMobile = {isMobile}
              mailTo = {mailTo}
              phoneNumber = {phoneNumber}
              textUpdate = {textUpdate}
            />
          </div>
        </div>
      );
    case "bank":
      var textUpdate = updateBank;
      return (
        <div className="content-text-confirm">
          <div className="item-info">
            <p className="label-info">Tên đăng nhập:</p>
            <p className="info-text">{user.account}</p>
          </div>
          <div className="item-info">
            <p className="label-info">Ngân hàng mới:</p>
            <p className="info-text">{dataUpdate.bank_number}-{dataUpdate.bank_name}</p>
          </div>
          <div className="box-footer">
            <div className="btn-copy-text">
              <button onClick={() => copyToClipboardAndSendMailSMS(textUpdate)}> Sao chép nội dung </button>
              {copySuccess && <p className="ss-copy">{copySuccess}</p>}
            </div>
            < SendButton
              send_type = {user.send_type}
              isMobile = {isMobile}
              mailTo = {mailTo}
              phoneNumber = {phoneNumber}
              textUpdate = {textUpdate}
            />
          </div>
        </div>
      );
    default:
      return Swal.fire({ icon: "error", text: "Thao tác lỗi, vui lòng thử lại." });
  }
}

export default DataConfirm;
