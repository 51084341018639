import React, { useEffect, useState } from 'react';
import "../../assets/styles/Search.css";
import Swal from "sweetalert2";
import imgbgContentCskh from "../../imgs/mb66/bg-content-cskh.png";
import iconSearch from "../../icons/icon-search.png";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { config } from "../../config.js";
import { apiCheckAccount, apiGetSetting } from "../../services/api.service.js";

function SearchUser() {
  const [userName, setUserName] = useState("");
  const [domains, setDomains] = useState("");
  const [webSetting, setWebSetting] = useState("");
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        let result = await apiGetSetting();
        if (result && result.status_code === 200) {
          setWebSetting(result.data);
        }
      } catch (error) {
        return Swal.fire({ icon: "error", text: "Lỗi kết nối, vui lòng liên hệ CSKH để được hỗ trợ." });
      }
    };
    fetchData();
  }, []);

  const checkUser = async () => {
      if (!userName) {
        return Swal.fire({ icon: "error", text: "Vui lòng nhập tên tài khoản." });
      } else {
        let result = await apiCheckAccount(userName);
        if (result && result?.code === 200) {
          let data = result.data;
          navigate("/profile",{ state: { userName, data, webSetting } });
        }
      }
  };

  return (
    <div className="container">
      <div className="app">
        <Header isPageSearch={true} />
        <div className="content">
          <div className="search-user">
            <span>VUI LÒNG XÁC NHẬN TÀI KHOẢN CỦA BẠN</span>
            <div className="input-search">
              <img className="icon-search" alt="icon-search" src={iconSearch} />
              <input className="input-account"
                onChange={(e) => setUserName(e.target.value.toLowerCase())}
                type="text"
                placeholder="Nhập tên đăng nhập"
              />
              <button className="btn-search-account" onClick={checkUser}>KIỂM TRA</button>
            </div>
            <div className="description" dangerouslySetInnerHTML={{ __html: webSetting?.login_info_notice }} >
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchUser;
