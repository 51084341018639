import "../../assets/styles/InfoUser.css";
import { useLocation } from 'react-router-dom';


function InfoUser() {
  const location = useLocation();
  const data = location.state?.data;
  return (
    <div class="profile-wrapper">
      <div className="profile-content">
        <div className="info">
          <span>Họ Và Tên</span>
          <span>{data.Name}</span>
        </div>
        <div className="info">
          <span>Số điện thoại</span>
          <span>{data.phone_number}</span>
        </div>
        <div className="info">
          <span>Gmail</span>
          <span>{data.email}</span>
        </div>
        <div className="info">
          <span>Ngân hàng</span>
          <span>{data.bank_name}</span>
        </div>
        <div className="info">
          <span>Chi nhánh</span>
          <span>{data.Province}</span>
        </div>
        <div className="info">
          <span>Số tài khoản</span>
          <span>{data.bank_number}</span>
        </div>
      </div>
    </div>
  );
}

export default InfoUser;
